<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col v-for="(v, index) in sortBallsFront" :key="index" :value="v"
          class="d-flex align-center justify-center mx-auto">
          <v-img v-if="v != superBall" contain :max-height="imgHeight" :max-width="imgWidth"
            :src="require('@/assets/img/balls/bingo/purple/' + v + '.png')">
          </v-img>
          <v-img v-if="v == superBall" contain :max-height="imgHeight" :max-width="imgWidth"
            :src="require('@/assets/img/balls/bingo/red/' + v + '.png')">
          </v-img>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col v-for="(v, index) in sortBallsBack" :key="index" :value="v"
          class="d-flex align-center justify-center mx-auto py-1">
          <v-img v-if="v != superBall" contain :max-height="imgHeight" :max-width="imgWidth"
            :src="require('@/assets/img/balls/bingo/purple/' + v + '.png')">
          </v-img>
          <v-img v-if="v == superBall" contain :max-height="imgHeight" :max-width="imgWidth"
            :src="require('@/assets/img/balls/bingo/red/' + v + '.png')">
          </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'BingoBalls',
  props: ["balls"],
  data() {
    return {
      sortBalls: [],
      sortBallsFront: [],
      sortBallsBack: [],
      superBall: 0,
      imgHeight: 20,
      imgWidth: 20,
    }
  },
  mounted() {
    console.log(this.balls)

    this.superBall = this.balls[19]

    this.sortBalls = this.balls.sort(function (a, b) { return a - b; });
    console.log(this.sortBalls);
    this.sortBallsFront = this.sortBalls.slice(0, 10);
    this.sortBallsBack = this.sortBalls.slice(10, 20);

  }

}
</script>
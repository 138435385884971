<template>

  <v-container class="py-0 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/lottery-area.jpg')}) center center fixed; background-size: cover;`">
      <v-row no-gutters class="mx-4 text-center">
        <v-col cols="12" align="center" justify="center" class="align-center justify-center text-center">
          <v-container class="text-center">
            <div
              style="display: flex; align-items: center; justify-items: center; align-items: center; justify-content: center;"
              class="text-center">
              <v-img src="@/assets/img/bingo_bingo.svg" style="display: flex; max-width:40%"></v-img>
              <h3 class="text-h3 text-typo font-weight-bold mx-0" style="display: inline-flex">
                近期開獎
              </h3>
            </div>
          </v-container>
        </v-col>
        <v-row no-gutters class="mb-3">
          <v-col cols="2" class="d-flex text-typo text-no-wrap font-weight-black align-center justify-center"><label style="font-size:10px">超獎猜單雙</label></v-col>
          <v-col cols="2" class="d-flex text-typo text-no-wrap font-weight-black align-center justify-center"><label style="font-size:10px">超獎猜大小</label></v-col>
          <v-col cols="8" class="d-flex text-typo text-no-wrap font-weight-black align-center justify-center"><label style="font-size:10px">開獎號碼</label></v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-col v-for="(item, i) in list" :key="i" cols="12" class="py-2">
              <v-card class="card-shadow border-radius-xl pa-1">
                <v-row no-gutters class="px-0" align="center" justify="center">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="2" class="d-flex align-center justify-center text-typo text-no-wrap font-weight-black">
                        <label style="font-size:28px" :style="{'color':item.odd_or_even_int==1?'#1A237E':'#9FA8DA'}">{{item.odd_or_even_str}}</label>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="2" class="d-flex align-center justify-center text-typo text-no-wrap font-weight-black">
                        <label style="font-size:28px" :style="{'color':item.big_or_small_int==1?'#1A237E':'#9FA8DA'}">{{item.big_or_small_str}}</label>
                      </v-col>
                      <v-col cols="8" class="d-flex align-center justify-center">
                        <v-card>
                          <bingo-balls :balls="item.balls"></bingo-balls>
                          <div>
                            <p class="d-flex align-end justify-end text-typo font-weight-bold mb-0"
                              style="font-size:12px">{{ item.draw_term }}</p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Cookie from "js-cookie";
import BingoBalls from "./Widgets/BingoBalls.vue";
import { bingoData } from "@/apis/lotteryHistoryService";

export default {
  name: "LotteryArea",
  components: {
    BingoBalls,
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    const token = Cookie.get('auth')
    const tokenParts = token.split('.')
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    const data = {
      'offset': 0,
      'size': 20,
    };

    bingoData(data)
      .then(response => {
        this.repsData = response.data;
        console.log(this.repsData)
        this.list = this.repsData.list

      })
      .catch(error => { // 请求失败处理
        console.log(error);
      });

  },
};
</script>
